<template>
  <div class="auth-page">
    <b-container>
      <b-card
        class="widget-auth mx-auto"
        :title="$t('general.password_change')"
        customHeader
      >
        <b-alert variant="danger" :show="!this.password_ok">
          {{ $t("general.password_minimal_requirements") }}
        </b-alert>
        <form class="mt-2" @submit.prevent="submit">
          <b-alert variant="danger" :show="!!error_message">
            {{ error_message }}
          </b-alert>
          <b-alert class="alert-sm" variant="success" :show="!!success_message">
            {{ success_message }}
          </b-alert>
          <div class="form-group">
            <input
              class="form-control no-border"
              ref="password"
              required
              type="password"
              name="password"
              v-model="password"
              :placeholder="$t('general.password_new')"
            />
          </div>
          <div class="form-group">
            <input
              class="form-control no-border"
              ref="password_confirm"
              required
              type="password"
              name="password_confirm"
              v-model="password_confirm"
              :placeholder="$t('general.password_confirmation')"
            />
          </div>
          <b-row>
            <b-col md="6">
              <b-button
                type="submit"
                class="auth-btn mb-3"
                variant="custom"
                :disabled="!this.password_ok"
                >{{ $t("general.update_password") }}</b-button
              >
            </b-col>
            <b-col md="6">
                <router-link
                  class="btn btn-primary btn-sm"
                  :to="$i18nRoute({ name: 'Home' })"
                >
                  {{ $t("menu.backhome") }}
                </router-link>
                <a href="" class="auth-btn mb-3"></a>
            </b-col>
          </b-row>
        </form>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      error_message: null,
      success_message: null,
      userHash: null,
      token: null,
      password: null,
      password_confirm: null,
      password_ok: false,
    };
  },
  methods: {
    ...mapActions("auth", { resetPassword: "resetPassword" }),
    passwordSuccess() {
      this.success_message = this.$t("general.password_change_success");
      this.password = null;
      this.password_confirm = null;
    },
    passwordError() {
      this.error_message = this.$t("general.password_change_error");
    },
    submit() {
      let password = this.password;
      let password_confirm = this.password_confirm;
      let userHash = this.userHash;
      let token = this.token;

      this.resetPassword({
        password,
        password_confirm,
        userHash,
        token,
      })
        .then(() => this.passwordSuccess())
        .catch(() => this.passwordError());
    },
  },
  mounted() {
    // get parameters sent via route
    this.userHash = this.$route.params.userHash;
    this.token = this.$route.params.token;
  },
  watch: {
    password_confirm: function () {
      var re =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.#-])[A-Za-z\d@$!%*?&.#-]{6,}$/;

      this.password_ok =
        this.password_confirm == this.password &&
        re.test(this.password) &&
        re.test(this.password_confirm);
    },
    password: function () {
      var re =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.#-])[A-Za-z\d@$!%*?&.#-]{6,}$/;
      this.password_ok =
        this.password_confirm == this.password &&
        re.test(this.password) &&
        re.test(this.password_confirm);
    },
  },
  computed: {
    ...mapGetters(["getLocale"]),
  },
};
</script>

<style>
</style>